import { getLogger as getSSRLogger } from './loggerInstance'

/* eslint-disable no-console */
const defaultMeta = {
  instanceType: 'storefront'
}

type logMsgType = 'error' | 'info' | 'warn' | 'debug'

/**
 * The Logger class provides logging utility methods.
 */
class StorefrontLogger {
  private readonly meta: Record<string, string>
  private constructor(meta: Record<string, string>) {
    this.meta = meta
  }

  /**
   * Returns the logger object for the given meta.
   */
  static getLogger(meta?: Record<string, string>) {
    const ssrLogger = getSSRLogger()
    if (ssrLogger) {
      return ssrLogger.getLogger(meta)
    }
    return new StorefrontLogger({ ...(meta || {}), ...defaultMeta })
  }

  with(extraMeta: Readonly<Record<string, string>>) {
    return new StorefrontLogger({ ...this.meta, ...extraMeta })
  }

  log(msgType: logMsgType, ...args: any[]) {
    const ssrLogger = getSSRLogger()

    if (ssrLogger) {
      ssrLogger.getLogger(this.meta).log(msgType, ...args)
    } else if (typeof process !== 'undefined' && process.env.NODE_ENV === 'production') {
      if (msgType === 'error') {
        console[msgType](...args)
      }
    } else {
      console[msgType](...args)
    }
  }

  /**
   * The method reports an debug level message.
   */
  debug(...args: any[]) {
    this.log('debug', ...args)
  }

  /**
   * The method reports an error level message.
   */
  error(...args: any[]) {
    this.log('error', ...args)
  }

  /**
   * The method reports an warning level message.
   */
  warn(...args: any[]): void {
    this.log('warn', ...args)
  }

  /**
   * The method reports an information level message.
   * */
  info(...args: any[]) {
    this.log('info', ...args)
  }
}

export const Logger = StorefrontLogger
